




























































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  FETCH_ANALYZED_RESUME,
  GET_ANALYZED_RESUMES,
  GETTER_ANALYZED_RESUMES,
  REMOVE_ANALYZED_RESUME,
  RESET_ANALYZED_RESUMES
} from "@/store/modules/skill_extractor/constants";
import { DataOptions, DataTableHeader } from "vuetify";
import RemoveConfirmationDialog from "@/components/shared/RemoveConfirmationDialog.vue";
import { date_format } from "@/utils/skill_extractor";
export default Vue.extend({
  name: "SkillExtractorResumesList",
  components: { RemoveConfirmationDialog },
  data() {
    return {
      analyzed_resumes_loading: false, // Loading
      analyzed_resumes_table_headers: [
        {
          text: "#",
          sortable: false,
          value: "index",
          align: "start"
        },
        {
          text: "File Name",
          sortable: false,
          value: "uploaded_file_name",
          align: "start"
        },
        {
          text: "Date",
          sortable: false,
          value: "created_at",
          align: "start"
        },
        {
          text: "Action",
          sortable: false,
          value: "action",
          align: "start"
        }
      ] as Array<DataTableHeader>,
      per_page_options: [10, 20, 30, 40],
      options: {} as DataOptions, // Data table options
      remove_resume_dialog: false, // For toggling remove resume dialog
      remove_resume_id: "" // Hold the upload_response_id of resume for soft removing purpose
    };
  },
  watch: {
    // Watcher to run after every interaction in data table
    options: {
      handler() {
        this.process_resumes_fetching();
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters("skill_extractor", {
      getter_analyzed_resumes: GETTER_ANALYZED_RESUMES // Get analyzed resumes from store
    })
  },
  methods: {
    date_format,
    ...mapActions("skill_extractor", {
      get_analyzed_resumes: GET_ANALYZED_RESUMES, // Fetch analyzed resumes with pagination
      fetch_analyzed_resume: FETCH_ANALYZED_RESUME, // Fetch specific analyzed resume
      remove_analyzed_resume: REMOVE_ANALYZED_RESUME // Remove specific analyzed resume
    }),
    ...mapMutations("skill_extractor", {
      reset_analyzed_resumes: RESET_ANALYZED_RESUMES // Remove all analyzed resumes from local store
    }),
    /**
     * Function to open remove resume dialog & setting remove_resume_id
     * @param upload_response_id
     */
    open_remove_resume_dialog({
      upload_response_id
    }: {
      upload_response_id: string;
    }) {
      this.remove_resume_dialog = true;
      this.remove_resume_id = upload_response_id;
    },
    /**
     * Function to close remove resume dialog & resetting remove_resume_id
     * @param payload
     */
    close_remove_resume_dialog(payload: boolean) {
      this.remove_resume_dialog = payload;
      this.remove_resume_id = "";
    },
    /**
     * Function to remove resume: get upload_response_id from local variable (remove_resume_id)
     */
    async remove_resume() {
      this.analyzed_resumes_loading = true;
      const upload_response_id = this.remove_resume_id;
      this.close_remove_resume_dialog(false); // Close dialog
      // Remove resume
      await this.remove_analyzed_resume(upload_response_id);
      this.analyzed_resumes_loading = false;
    },
    /**
     * Function to get analyzed resumes: Attached with data table
     */
    async process_resumes_fetching() {
      this.analyzed_resumes_loading = true;
      this.reset_analyzed_resumes();
      let item_per_page = this.options.itemsPerPage;
      let page_number = this.options.page;
      // If user select all
      if (item_per_page === -1)
        item_per_page =
          this.getter_analyzed_resumes.total === 0
            ? 10
            : this.getter_analyzed_resumes.total;
      // set item_per_page = 10 => If item per page greater then 40
      if (item_per_page > 40) item_per_page = 10;
      if (item_per_page && page_number) {
        await this.get_analyzed_resumes({ item_per_page, page_number });
        this.analyzed_resumes_loading = false;
      }
    },
    /**
     * Function to fetch specific analyzed resume from DB & navigate to extract_skills view
     * @param upload_response_id
     */
    view_analyzed_resume({
      upload_response_id
    }: {
      upload_response_id: string;
    }) {
      this.fetch_analyzed_resume(upload_response_id);
    },
    /**
     * Format file name. Remove date from file name
     * @param value: string value stored in DB as uploaded_file_name
     * @return string: formatted file name
     */
    format_file_name(value: string): string {
      return value.split("-").slice(1).join(" ");
    }
  }
});
