var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('Transition',{attrs:{"appear":"","enter-class":"opacity-enter-from","enter-active-class":"opacity-enter-active","enter-to-class":"opacity-enter-to","leave-class":"opacity-leave-from","leave-active-class":"opacity-leave-active","leave-to-class":"opacity-leave-to"}},[_c('div',{staticClass:"my-3 mb-5 d-flex align-center justify-space-between"},[_c('p',{staticClass:"section-main-heading mb-0"},[_vm._v("Analyzed Resumes")]),_c('Transition',{attrs:{"appear":"","enter-class":"m-right-enter-from","enter-active-class":"m-right-enter-active","enter-to-class":"m-right-enter-to","leave-class":"m-right-leave-from","leave-active-class":"m-right-leave-active","leave-to-class":"m-right-leave-to"}},[_c('v-btn',{staticClass:"base-btn analyze-resume-btn px-sm-10 py-5 elevation-0",attrs:{"link":"","to":"/skill-extractor/resume-analysis"}},[_vm._v(" Skill Extractor ")])],1)],1)]),_c('v-data-table',{key:1,attrs:{"loading":_vm.analyzed_resumes_loading,"loader-height":"6","headers":_vm.analyzed_resumes_table_headers,"items":_vm.getter_analyzed_resumes.results,"server-items-length":_vm.getter_analyzed_resumes.total,"options":_vm.options,"footer-props":{
      itemsPerPageOptions: _vm.per_page_options
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
    var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.uploaded_file_name",fn:function(ref){
    var item = ref.item;
    var value = ref.value;
return [(item['upload_type'] === 'file')?[_vm._v(" "+_vm._s(_vm.format_file_name(value))+" ")]:[_vm._v(" text.txt ")]]}},{key:"item.created_at",fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.date_format(value))+" ")]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"view-icon",attrs:{"icon":""},on:{"click":function($event){return _vm.view_analyzed_resume(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"del-icon",attrs:{"icon":""},on:{"click":function($event){return _vm.open_remove_resume_dialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('Transition',{attrs:{"appear":"","enter-class":"m-top-enter-from","enter-active-class":"m-top-enter-active","enter-to-class":"m-top-enter-to","leave-class":"m-top-leave-from","leave-active-class":"m-top-leave-active","leave-to-class":"m-top-leave-to"}},[(_vm.remove_resume_dialog)?_c('RemoveConfirmationDialog',{on:{"close_remove_resume_dialog":_vm.close_remove_resume_dialog,"remove_resume":_vm.remove_resume}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }